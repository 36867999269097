import React from "react"
import Helmet from "react-helmet"
import { graphql, Link } from "gatsby"
import Layout from "../layout"
import config from "../../data/SiteConfig"
import ReferenceItems from "../components/ReferenceItems/ReferenceItems"
import Hero from "../components/Hero/Hero"
import GameListing from '../components/GameListing/GameListing'
import GameNav from '../components/GameNav/GameNav'
import NewsListing from '../components/NewsListing/NewsListing'
import BetSiteListing from '../components/BetSiteListing/BetSiteListing'
import BetSiteListing2 from '../components/BetSiteListing2/BetSiteListing2'
import BetBonusListing from '../components/BetBonusListing/BetBonusListing'
import Faq from "../components/Faq/Faq"
import InfoBlocks from "../components/InfoBlocks/InfoBlocks"

class NotFoundPage extends React.Component {
    
    render () {
    const sites = this.props.data.allPrismicBetsite.edges
    return (
      <Layout>
        <div className="index-container">
        <Helmet>
                <title>{`${config.siteTitle}`}</title>
                <meta name="description" content={config.siteDescription} />
                <meta name="image" content={config.siteLogo} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="og:url" content={config.siteUrl} />
                <meta property="og:title" content={config.siteTitle} />
                <meta property="og:description" content={config.siteDescription} />
                <meta property="og:image" content={config.siteLogo} />
                {/* <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ""}/> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ""}/>
                <meta name="twitter:title" content={config.siteTitle} />
                <meta name="twitter:description" content={config.siteDescription} />
                <meta name="twitter:image" content={config.siteLogo} />
                <script type="application/ld+json" id="website-json-ld">
                  {JSON.stringify(
                    {
                      "@context":"http://schema.org",
                      "@type":"WebSite",
                      "name":"Esportstips",
                      "alternateName":"esportstips.net",
                      "url":"https://esportstips.net",
                      "@context":"http://schema.org",
                      "@type":"Organization",
                      "name":"Esportstips",
                      "url":"https://esportstips.net",
                      "logo":config.siteLogo,
                      // "sameAs":[
                      //     "https://www.facebook.com/esportstips",
                      //     "https://www.instagram.com/esportstips/",
                      //     "https://twitter.com/esportstips",
                      // ]
                    }
                  )}
                </script>
            </Helmet>
          <section className="section">
            <div className="container">
                <h1 className="title">Oh no, the page you were looking for doesn't exist.</h1>
                <p>
                    However you can take a look at our esport betting site reviews and offers 
                    <Link to="/betting-sites"> Esports betting sites</Link>
                </p>
            </div>
          </section>
          <section className="section">
            <div className="container">
                <h2 className="subtitle">Esports betting site bonuses and offers</h2>
                <BetBonusListing sites={sites}></BetBonusListing>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query NotFoundPageQuery {
      allPrismicBetsite(limit: 6) {
        edges {
          node {
            uid
            data {
              name {
                text
              }
              website {
                url
              }
              aff_link {
                url
              }
              terms_and_conditions_link {
                url
              }
              bonus
              rating
              brand_color
              logo {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;